class ModalManager {
    constructor() {
        this.openButtons = document.querySelectorAll(".modalBtn");
        this.closeButtons = document.querySelectorAll(".o-modal__close");
        this.activeModal = null;

        this.init();
    }

    init() {
        this.openButtons.forEach(btn => {
            btn.addEventListener("click", () => this.openModal(btn.getAttribute("data-target")));
        });

        this.closeButtons.forEach(btn => {
            btn.addEventListener("click", () => this.closeModal(btn.getAttribute("data-target")));
        });

        document.addEventListener("keydown", (event) => this.handleKeydown(event));
    }

    openModal(targetId) {
        const targetModal = document.getElementById(targetId);
        if (!targetModal) return;

        const videoIframe = targetModal.querySelector(".o-modal__iframe");
        const videoElement = targetModal.querySelector("video");
        document.body.style.overflow = 'hidden';
        targetModal.classList.add("show");
        setTimeout(() => {
            targetModal.style.display = "block";
            this.activeModal = targetModal;

            this.autoplayMedia(videoIframe, videoElement);
        }, 100);
    }

    closeModal(targetId) {
        const targetModal = document.getElementById(targetId);
        if (!targetModal) return;

        const videoIframe = targetModal.querySelector(".o-modal__iframe");
        const videoElement = targetModal.querySelector("video");
        document.body.style.overflow = 'visible';
        this.stopMedia(videoIframe, videoElement);

        targetModal.classList.remove("show");
        setTimeout(() => {
            targetModal.style.display = "none";
            this.activeModal = null;
        }, 100);
    }

    autoplayMedia(videoIframe, videoElement) {
        if (videoIframe) {
            const src = videoIframe.src;
            videoIframe.src = src.includes("?")
                ? src + "&autoplay=1&rel=0&controls=0"
                : src + "?autoplay=1&rel=0&controls=0";
        }

        if (videoElement) {
            videoElement.play();
        }
    }

    stopMedia(videoIframe, videoElement) {
        if (videoIframe) {
            videoIframe.src = videoIframe.src.split("&autoplay")[0].split("?autoplay")[0];
        }

        if (videoElement) {
            videoElement.pause();
            videoElement.currentTime = 0;
        }
    }

    handleKeydown(event) {
        if (event.code === "Space" && this.activeModal) {
            event.preventDefault();
            const videoIframe = this.activeModal.querySelector(".o-modal__iframe");
            const videoElement = this.activeModal.querySelector("video");

            this.toggleMedia(videoIframe, videoElement);
        }
    }

    toggleMedia(videoIframe, videoElement) {
        if (videoIframe) {
            const src = videoIframe.src;
            if (src.includes("autoplay=1")) {
                videoIframe.src = src.split("&autoplay")[0].split("?autoplay")[0];
            } else {
                videoIframe.src = src.includes("?")
                    ? src + "&autoplay=1&rel=0&controls=0"
                    : src + "?autoplay=1&rel=0&controls=0";
            }
        }

        if (videoElement) {
            if (videoElement.paused) {
                videoElement.play();
            } else {
                videoElement.pause();
            }
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    new ModalManager();
});